<template>
  <section id="dashboard-ecommerce">
    <b-row
      v-if="!loading && data"
      class="match-height"
    >
      <b-col
        lg="4"
        md="4"
        sm="12"
      >
        <b-card>
          <b-card-title>
            Total de modelos
          </b-card-title>
          <b-card-text class="font-large-1">
            {{ data.model_agents_total }}
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        lg="4"
        md="4"
        sm="12"
      >
        <b-card>
          <b-card-title>
            Con suscripción activa
          </b-card-title>
          <b-card-text class="text-success font-large-1">
            {{ data.active_subscription_model_agents }}
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        lg="4"
        md="4"
        sm="12"
      >
        <b-card>
          <b-card-title>
            Con suscripción inactiva
          </b-card-title>
          <b-card-text class="text-danger font-large-1">
            {{ data.inactive_subscription_model_agents }}
          </b-card-text>
        </b-card>
      </b-col>

    </b-row>

    <b-row
      v-if="!loading && data"
      class="match-height"
    >
      <b-col
        lg="4"
        md="4"
        sm="12"
      >
        <b-card>
          <b-card-title>
            Modelos por destino
          </b-card-title>
          <table class="table table-striped">
            <tr>
              <th class="text-center">
                Destino
              </th>
              <th class="text-center">
                Modelos
              </th>
            </tr>
            <tr
              v-for="destination in data.destination_model_agents_count"
              :key="destination.id"
            >
              <td class="text-center">
                {{ destination.name }}
              </td>
              <td class="text-center">
                {{ destination.model_agent_destinations_count }}
              </td>
            </tr></table>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="loading">
      <b-col class="text-center">
        <b-spinner label="Loading..." />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCol, BRow, BSpinner, BCard, BCardTitle, BCardText,
} from 'bootstrap-vue'
import GeneralService from '@/dc-it/services/GeneralService'

export default {
  name: 'DashboardView',
  components: {
    BRow,
    BCol,
    BSpinner,
    BCard,
    BCardTitle,
    BCardText,
  },
  data() {
    return {
      data: null,
      loading: false,
    }
  },
  mounted() {
    this.loading = true
    GeneralService.getInstance().all('dashboard').then(response => {
      console.log(response)
      if (response.result === 'success') {
        this.data = response.data
      }
      this.loading = false
    })
  },
}
</script>

<style scoped>

</style>
